<template>
  <div :class="$style.perfect">
    <div :class="$style.box">
      <div :class="$style.form">
        <div :class="$style.title">
          <i :class="$style.back" class="ri-arrow-left-line" @click="backLogin()" />
          创建文化云
        </div>
        <BjCard :class="$style.card" :title="tips" />
        <BjForm ref="Form">
          <BjInput
            v-model="user.name"
            label="文化云名称"
            v-bind="layout"
            label-align="left"
            :max-length="10"
            rules="required"
            placeholder="请输入文化云名称"
            @blur="getInitials()"
          />
          <BjSelect
            v-model="user.type"
            v-bind="layout"
            label-align="left"
            rules="required"
            key-field="value"
            label="文化云类型"
            placeholder="请选择文化云类型"
            value-field="id"
            label-field="name"
            show-all
            :options="typeData"
          />
          <a-tooltip placement="top">
            <template slot="title">
              <span>用于你的文化云前端访问地址，一旦设置不能修改。</span>
            </template>
            <BjInput
              v-model="user.sub_domain"
              label="个性化域名"
              v-bind="layout"
              label-align="left"
              rules="required"
              :max-length="10"
              placeholder="请输入个性化域名"
              @keyup="user.sub_domain = user.sub_domain.replace(/[^a-zA-Z0-9]/g, '')"
            >
              <span slot="suffix">{{ globalUser.main_domain }}</span>
            </BjInput>
          </a-tooltip>

          <BjInput
            v-model="user.organization_name"
            label="机构名称"
            v-bind="layout"
            label-align="left"
            rules="required"
            placeholder="请输入机构全称"
          />
          <BjValidationItem
            label="机构类型"
            rules="required"
            :label-col="layout.labelCol"
            :wrapper-col="layout.wrapperCol"
            label-align="left"
          >
            <bj-cascader
              v-model="user.organization_type"
              :options="organizationData"
              :field-names="{
                label: 'name',
                value: 'id',
                children: 'children',
              }"
              :allow-clear="false"
              placeholder="请选择机构类型"
            />
          </BjValidationItem>
          <BjValidationItem
            label="所在地区"
            rules="required"
            :label-col="layout.labelCol"
            :wrapper-col="layout.wrapperCol"
            label-align="left"
          >
            <bj-cascader
              v-model="area"
              :options="areaList"
              :field-names="{
                label: 'area_name',
                value: 'area_id',
                children: 'children',
              }"
              placeholder="请选择所在地区"
              :allow-clear="false"
              :load-data="loadData"
            />
          </BjValidationItem>
          <a-row>
            <a-col class="line-40" span="6">
              <span class="required">*</span>
              联系信息
            </a-col>
            <a-col span="18">
              <BjInput
                v-model="user.contacts"
                class="mr-20"
                :class="$style.double"
                :show-label="false"
                label="联系姓名"
                label-align="left"
                rules="required"
                placeholder="请输入联系人姓名"
              />
              <BjInput
                v-model="user.contacts_tel"
                :class="$style.double"
                :show-label="false"
                label="联系电话"
                label-align="left"
                rules="required"
                placeholder="请输入联系人电话"
              />
            </a-col>
          </a-row>
        </BjForm>
      </div>
      <div :class="$style.right">
        <div :class="$style.btn" @click="create()">
          <i :class="$style.icon" class="ri-arrow-right-line" />
        </div>
      </div>
    </div>
    <div :class="$style.footer">
      <a :href="official.web_site" class="primary" target="_blank">{{ official.official_name }}</a
      >提供技术支持
      <span class="ml-5">
        ©{{ year }}{{ official.principal_name }}
        <a class="default-link ml-5" href="https://beian.miit.gov.cn/" target="_blank">{{ official.icp_no }}</a>
      </span>
    </div>
  </div>
</template>

<script>
// import _ from 'lodash'
import { mapMutations, mapState } from 'vuex'

import { systemService } from '@/service'
import { areaLevel } from '@/utils/variable'

const service = new systemService()

export default {
  name: 'perfect',
  data() {
    return {
      tips: '请根据下面提示完善相关信息。信息完善后点击右侧箭头即可进入文化云平台，如需帮助请通过网站与我们取得联系。',
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
      },
      user: {
        name: null,
        type: [],
        organization_name: null,
        organization_type: [],
        province_id: null,
        city_id: null,
        county_id: null,
        contacts: null,
        contacts_tel: null,
        sub_domain: null,
      },
      area: [],
      typeData: [],
      organizationData: [],
      areaList: [],
      year: 2022, // 当前年份
    }
  },
  computed: {
    globalUser() {
      return this.$store.state.user
    },
    ...mapState(['official']),
  },
  created() {
    this.getType()
    this.getOrganization()
    this.year = new Date().getFullYear()
  },
  async mounted() {
    await this.getRegion()
  },
  methods: {
    ...mapMutations(['FINISH', 'LOGOUT']),
    async getType() {
      const { data } = await service.cloudType()
      this.typeData = data
    },
    async getOrganization() {
      const { data } = await service.organizationType()
      this.organizationData = data
    },
    async create() {
      this.$refs.Form.validate().then(e => {
        if (!e) {
          this.$message.warning('你需要完善所有信息才能进入文化云平台')
          return
        }
        this.onSubmit()
      })
    },
    async onSubmit() {
      try {
        const { data } = await service.create({
          ...this.user,
          province_id: this.area[0],
          city_id: this.area[1],
          county_id: this.area[2],
          organization_type: this.user.organization_type[this.user.organization_type.length - 1],
        })
        this.$message.success('完善信息成功')
        this.FINISH(data)
      } catch (e) {}
    },
    async getRegion() {
      const { data } = await service.getArea({ area_type: 'province' })
      this.areaList = data
      this.areaList.map(item => {
        item.isLeaf = false
        return item
      })
    },
    async loadData(e) {
      const targetOption = e[e.length - 1]
      targetOption.loading = true
      const { data } = await service.getArea({
        area_type: areaLevel[e.length],
        parent_area_id: targetOption.area_id,
      })
      targetOption.loading = false
      targetOption.children = data
      if (e.length !== 2) {
        targetOption.children.map(item => {
          item.isLeaf = false
          return item
        })
      }
      this.areaList = [...this.areaList]
    },
    backLogin() {
      if (this.$store.state.user.created_cultural_cloud === 0) {
        this.LOGOUT()
      } else {
        this.$router.push({
          name: 'control',
        })
      }
    },
    async getInitials() {
      // 获取首字母给填充哈
      if (this.user.name && !this.user.sub_domain) {
        const { data } = await service.first_letter({
          name: this.user.name,
        })
        if (!this.user.sub_domain) {
          this.user.sub_domain = data
        }
      }
    },
  },
}
</script>

<style lang="less" module>
@oss: 'https://minio.ycwly.com/cultural-cloud';

.perfect {
  position: relative;
  height: 100vh;
  min-height: 800px;
  overflow: auto;
  background-color: #f5fcfd;
  background-image: url('@{oss}/manage/media/assets/login_bg.jpg');
  background-position: 50%;
  background-size: 2048px 1063px;

  :global {
    .ant-input {
      padding: 0;
      border: none;
      border-bottom: 1px solid #eee;
      border-radius: 0;
    }

    .ant-input:hover {
      border-color: @primary-color;
    }

    .ant-input:focus {
      border-top: none;
      border-right: none;
      border-bottom: 1px solid @primary-color;
      border-left: none;
      box-shadow: none;
    }

    .has-error .ant-input-affix-wrapper .ant-input:focus {
      box-shadow: none;
    }

    .ant-popover-inner-content {
      padding: 10px;
    }

    .ant-select-selection {
      padding: 0;
      border: none;
      border-bottom: 1px solid #eee;
      border-radius: 0;
    }

    .ant-select-selection__rendered {
      margin-left: 0;
    }

    .ant-select-focused .ant-select-selection,
    .ant-select-selection:focus,
    .ant-select-selection:active {
      box-shadow: none;
    }

    .ant-form-item {
      margin-bottom: 15px;
    }

    .ant-cascader-picker:focus .ant-cascader-input {
      box-shadow: none;
    }

    .ant-cascader-picker-label {
      padding: 0;
    }
  }

  .box {
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    box-sizing: border-box;
    width: 880px;
    height: 600px;
    overflow: hidden;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
    transform: translate(-50%, -50%);

    .back {
      margin-right: 10px;
      font-size: 23px;
      vertical-align: -5px;
    }

    .back:hover {
      color: @primary-color;
    }

    .form {
      width: 450px;
      margin: 30px 100px 30px 30px;
      padding-right: 30px;
      border-right: 1px solid #eee;
    }

    .title {
      color: #000;
      font-size: 18px;
    }

    .card {
      margin: 25px 0 30px;
    }

    .right {
      position: relative;
      flex: 1;
      line-height: 600px;
      background: url('@{oss}/manage/media/assets/register_info.jpg') no-repeat;
      background-size: 100% 100%;
      border-radius: 300px 0 0 300px;

      .btn {
        display: inline-block;
        width: 80px;
        height: 80px;
        margin-left: 20px;
        line-height: 80px;
        text-align: center;
        background: @primary-color;
        border-radius: 40px;
        transform: translate(-50%, -50%);
        transform: scale(1);
        cursor: pointer;
        animation: mymove 2s linear infinite;
      }

      .icon {
        color: #fff;
        font-size: 38px;
      }

      @keyframes mymove {
        0% {
          transform: scale(1);
        }

        50% {
          transform: scale(1.05);
        }

        100% {
          transform: scale(1);
        }
      }
    }
  }

  .footer {
    position: absolute;
    bottom: 20px;
    left: 0;
    width: 100%;
    color: #5c5c5c;
    font-size: 12px;
    text-align: center;
  }

  .double {
    display: inline-block;
    width: calc(50% - 10px);
  }
}
</style>
